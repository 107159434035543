import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from '../helpers/stringHelpers';
import Table from '../components/Table/Table';

function InsiderDetailPage() {
  const { insiderId } = useParams();
  const [person, setPerson] = useState({});
  const [transactions, setTransactions] = useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    // Fetch person details
    fetch(`${API_ENDPOINT}/person-details/${insiderId}`)
      .then(response => response.json())  // Convert the response to JSON
      .then(data => {
        setPerson(data); 
        document.title = `${capitalizeFirstLetter(data.first_name)} ${capitalizeFirstLetter(data.last_name)} | Insider Trading`;
      }
        )
      .catch(error => console.error("Error fetching person details:", error));

    // Fetch recent transactions
    fetch(`${API_ENDPOINT}/person-recent-transactions/${insiderId}`)
    .then(response => response.json())
      .then(data => setTransactions(data))
      .catch(error => console.error("Error fetching recent transactions:", error));
  }, [insiderId]);

  return (
    <div className='container'>
      <div className='row'>
        <h1>{capitalizeFirstLetter(person.first_name)} {capitalizeFirstLetter(person.last_name)}</h1>
      </div>

      {person.detailed_role && <p className='mt-2'>Detailed Role: {person.detailed_role}</p>}
      {!transactions || transactions.length === 0 && <p>No recent transactions</p>}
      {transactions && transactions.length > 0 && (<>
        <Table endpoint={`person-recent-transactions/${insiderId}`} header={'Recent transactions'} />
      </>
      )}
    </div>
  );
}

export default InsiderDetailPage;
