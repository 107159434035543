import React, { useState } from 'react';
import axios from 'axios'; // Import Axios for making HTTP requests
import './Footer.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Footer() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    // message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to your server with the form data
      await axios.post(`${API_ENDPOINT}/newsletter-registration`, formData);
      // Optionally, you can show a success message or redirect the user
      toast.success('Thanks for signing up!');
    } catch (error) {
      // Handle errors, e.g., display an error message
      console.error('Error submitting form:', error);
      toast.error('Error submitting form');
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="lead-form">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <h4>Sign up to learn about updates about this site and others!</h4>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            {/* <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div> */}
            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <footer>
        <p>© 2023 Insider Trading Tracker</p>
      </footer>
    </>
  );
}

export default Footer;
