import React, { useState, useEffect } from 'react';

function AboutPage() {
  useEffect(() => {
    document.title = 'About | Insider Trading';
  }, []);

  return (
    <div className='container'>
      <div className='row'>
        <h1>About</h1>
      </div>
      <div className='row'>
        <p>Hello! I'm Dillon Pietsch, the creator of this platform. I created this site for my MS in Computer Science, and I used it to gain an even deeper understanding in full-stack web development and to better understand the potential of Artificial Intelligence (AI). This website is a testament to that passion.</p>

        <h2 className='mt-4'>Why This Website?</h2>
        <p>The financial world is vast, intricate, and often shrouded in complexity. One area that has always intrigued me is insider trading. Insiders in a company (those who are C-level execs, on the board, own 10%+, etc) are required to report stock transactions, which can sometimes provide insights into a company's future prospects. By gathering and analyzing this data, I aim to shed light on these trades and their potential implications.</p>
        

        <p>But there's more to it than just presenting data. My ultimate goal is to explore the possibilities of training AI models to predict stock movements post insider trades. Can we predict if a stock will rise or fall after an insider makes a transaction? That's the question I'm hoping to answer.</p>

        <h2 className='mt-4'>Connect with Me</h2>
        <p>I'm always eager to connect, discuss, and collaborate. You can find me on <a href="https://www.linkedin.com/in/dillonpietsch/">LinkedIn</a>, <a href="https://twitter.com/MrJustPeachy">X</a>, or visit my other project, <a href="https://fieldofpredictions.com">Field of Predictions</a>, where I delve deeper into the world of predictive analytics for sports.</p>

        <p>Thank you for visiting, and I hope you find the insights here valuable.</p>
      </div>
    </div>
  );
}

export default AboutPage;
