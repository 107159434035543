import React, { useState, useEffect } from 'react';
import Table from '../components/Table/Table';

function InsidersPage() {  
  useEffect(() => {
    document.title = 'Insiders | Insider Trading';
  }, []);
  return (
    <div className='container'>
      <div className='row'>
        <h1>Insiders</h1>
      </div>
      <div className='row'>
        <Table endpoint={'best-insiders/30'} header={'Top Performing Insiders'} />
      </div>
    </div>
  );
}

export default InsidersPage;
