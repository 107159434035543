import React from 'react';
import { Link } from 'react-router-dom';
import './Nav.scss';

function Nav() {
  return (
    <nav>
      <div className="container">
        <div className="row">
          <ul>
            <li className='nav-links'><Link className='nav-link' to="/">Home</Link></li>
            <li className='nav-links'><Link className='nav-link' to="/trades">Trades</Link></li>
            <li className='nav-links'><Link className='nav-link' to="/insiders">Insiders</Link></li>
            <li className='nav-links'><Link className='nav-link' to="/predictions">Predictions</Link><span className="bubble">AI</span></li>
            <li className='nav-links'><Link className='nav-link' to="/about">About</Link></li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
