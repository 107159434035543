import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../components/Table/Table';

function TradeDetailPage() {  
  useEffect(() => {
    document.title = 'Trade Details | Insider Trading';
  }, []);
  const { tradeId } = useParams();
  const [transaction, setTransaction] = useState({});
  const [otherTransactions, setOtherTransactions] = useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    // Fetch person details
    fetch(`${API_ENDPOINT}/trade-details/${tradeId}`)
      .then(response => response.json())  // Convert the response to JSON
      .then(data => setTransaction(data))
      .catch(error => console.error("Error fetching trade details:", error));
  }, [tradeId]);

  useEffect(() => {
    // Check if transaction and transaction.stock_id are defined
    if (transaction && transaction.stock_id) {
      fetch(`${API_ENDPOINT}/recent-ticker-trades/${transaction.stock_id}`)
        .then(response => response.json())  // Convert the response to JSON
        .then(data => setOtherTransactions(data))
        .catch(error => console.error("Error fetching trade details:", error));
    }
  }, [transaction]);

  return (
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-12'>
          <h1 className="mb-4">Transaction Details</h1>

          <table className="table-bordered mb-5">
            <thead>
              <tr>
                <th>Transaction #</th>
                <th>Date</th>
                <th>Type</th>
                <th>Ticker</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Ownership Change</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{transaction.id}</td>
                <td>{new Date(transaction.date_time).toLocaleDateString()}</td>
                <td>{transaction.transaction_type}</td>
                <td>{transaction.stock_id}</td>
                <td>${transaction.price}</td>
                <td>{transaction.quantity}</td>
                <td>{transaction.ownership_delta || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <h3 className="mt-5">Other Recent Transactions For {transaction.stock_id}</h3>
          {transaction && transaction.stock_id ? (
            <Table endpoint={`recent-ticker-trades/${transaction.stock_id}/${transaction.id}`} header={'Recent Transactions'} />
          ) : (
            <p>No recent transactions</p>
          )} 
        </div>
      </div>
    </div>
  );
}

export default TradeDetailPage;
