import { capitalizeFirstLetter } from "./stringHelpers";
import { roundTo } from "./stringHelpers";

function columnToUrl(col) {
    switch (col.toLowerCase()) {
      case 'name':
        return '/insiders/';
      case 'date':
        return '/trades/';
      default:
        return col;
    }
}

function numberHelper(col, value) {
    // if (value === undefined || value === null || typeof value !== 'number') {
    //   return value;
    // }
    
    if (col.toLowerCase().includes('price') || col.toLowerCase().includes('total') || col.toLowerCase().includes('stock prediction')) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);
    }
    else if (col.toLowerCase().includes('average') || col.toLowerCase().includes('Δ')) {
      return (value + '%')
    }

    return roundTo(value, 2);
}

export function transformToUrl(col, value) {
    if (typeof value === 'number' || col.toLowerCase().includes('price')) {
      return numberHelper(col, value);
    }

    if (value === undefined || value === null || typeof value !== 'string' || !value.includes('--')) {
      if (col.toLowerCase().includes('date')) {
        return new Date(value).toLocaleDateString();
      }

      return value;
    }

    const string = String(value).split('--');
    const url = columnToUrl(col) + string[1];
    return (<a href={url}>{capitalizeFirstLetter(string[0])}</a>)
  }