import React, { useState, useEffect } from 'react';
import './Table.scss';
import { prettifyUnderscores } from '../../helpers/stringHelpers';
import { transformToUrl } from '../../helpers/urlHelpers';

function Table({ endpoint, header, detailText, detailPath, limit = 20, paginationEnabled = false, columns_param=[], data_param=[] }) {
  const [allData, setAllData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    if (columns_param.length > 0 && data_param.length > 0) {
      setAllData(data_param);
      setTotalPages(Math.ceil(data_param.length / limit));
      setColumns(columns_param);
    } else {
      fetch(`${API_ENDPOINT}/${endpoint}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(fetchedData => {
          setAllData(fetchedData);
          setTotalPages(Math.ceil(fetchedData.length / limit));
          if (fetchedData.length > 0) {
            setColumns(Object.keys(fetchedData[0]));
          }
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error.message);
        });
    }
  }, []);

  useEffect(() => {
    const start = (currentPage - 1) * limit;
    const end = start + limit;
    setData(allData.slice(start, end));
  }, [allData, currentPage, limit]);

  return (
    <div className='table-container'>
      {(header || (detailPath && detailText)) && (
        <div className='table-title-container'>
          {header && (<h2>{header}</h2>)}
          {(detailPath && detailText) && (<a href={detailPath}>{detailText}</a>)}
        </div>
      )}
      {data.length > 0 ? (
        <table>
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index}>{prettifyUnderscores(col)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>{transformToUrl(col, row[col])}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No data found</p>
      )}
      {paginationEnabled && (
        <div className="pagination">
          <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default Table;