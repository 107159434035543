import React, { useState, useEffect } from 'react';
import Table from '../components/Table/Table';
import { roundTo } from '../helpers/stringHelpers';

function PredictionsPage() {  
  useEffect(() => {
    document.title = 'Predictions | Insider Trading';
  }, []);
  const [modelInfo, setModelInfo] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [pastPredictions, setPastPredictions] = useState({});
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    // Fetch model info
    fetch(`${API_ENDPOINT}/model-info/${1}`)
      .then(response => response.json())
      .then(data => setModelInfo(data));

    // Fetch recent predictions
    fetch(`${API_ENDPOINT}/recent-model-predictions/${1}`)
      .then(response => response.json())
      .then(data => setPredictions(data));

    // Fetch model performance stats
    fetch(`${API_ENDPOINT}/model-performance/${1}`)
      .then(response => response.json())
      .then(data => setPastPredictions(data));
  }, []);

  return (
    <div className='container'>
      <div className='row'>
        <h1>Predictions</h1>
        <p>This page and following model(s) are still under construction, but an extremely basic model is shown below.</p>
      </div>
      <div className='row'>
        <div className='col-12'>
          {modelInfo ? (
            <div>
              <h3 className='mt-3'>Model Info</h3>
              <p className='mb-0'>Model Name: {modelInfo.model_name}</p>
              <p className='mb-0'>Model Score: {roundTo(modelInfo.model_score, 5)} r^2</p>
            </div>
          ) : (
            <p>No model info found.</p>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-12 mb-5'>
          {predictions.length > 0 ? (
            <Table
              columns_param={['ticker', 'prediction_start_date', 'prediction_end_date', 'stock_price_movement']}
              data_param={predictions}
              header={'Upcoming Predictions'}
            />
          ) : (
            <p>No upcoming predictions found.</p>
          )}
        </div>
        {/* <div className='col'>
          
          {pastPredictions.length > 0 ? (
            <Table
              columns_param={['ticker', 'prediction_start_date', 'prediction_end_date', 'stock_price_movement']}
              data_param={pastPredictions}
              header={'Past Predictions'}
            />
          ) : (
            <><h2>Model Performance</h2>
            <p>No past predictions found.</p></>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default PredictionsPage;
