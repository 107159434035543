export function capitalizeFirstLetter(value) {
    const string = String(value);
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

export function prettifyUnderscores(value) {
    const string = String(value);
    return string
      .split('_')
      .map(word => capitalizeFirstLetter(word))
      .join(' ');
  }

export function roundTo(value, places) {
    const num = Number(value);
    return num.toFixed(places);
}