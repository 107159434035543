import React, { useState, useEffect } from 'react';
import Table from '../components/Table/Table';

function TradesPage() {  
  useEffect(() => {
    document.title = 'Trades | Insider Trading';
  }, []);
  return (
    <div className='container'>
      <div className='row'>
        <h1>Trades</h1>
      </div>
      <div className='row'>
        <Table endpoint={'recent-trades'} paginationEnabled={true}/>
      </div>
    </div>
  );
}

export default TradesPage;
