import React, { useState, useEffect } from 'react';
import Table from '../components/Table/Table';

function HomePage() {
  useEffect(() => {
    document.title = 'Home | Insider Trading';
  }, []);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  return (
    <div className='container'>
      <div className='row'>
        <h1>Insider Trading Tracker</h1>
        {/* <p>This is the home page content.</p> */}
      </div>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <Table endpoint={'recent-trades'} header={'Latest Trades'} detailText={'See all'} detailPath={'/trades'} />
        </div>
        <div className='col-12 col-md-6'>
          <Table endpoint={'best-insiders/30'} header={'Top Performing Insiders'} detailText={'See all'} detailPath={'/insiders'} />
        </div>
      </div>

    </div>
  );
}

export default HomePage;
