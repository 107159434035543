import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './components/Nav/Nav';
import Footer from './components/Footer/Footer';
import HomePage from './pages/HomePage';
import TradesPage from './pages/TradesPage';
import TradesDetailPage from './pages/TradeDetailPage';
import InsidersPage from './pages/InsidersPage';
import PredictionsPage from './pages/PredictionsPage';
import AboutPage from './pages/AboutPage';
import InsiderDetailPage from './pages/InsiderDetailPage';

function App() {
  return (
    <Router>
      <Nav />
      <div className='mt-5'>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/trades" element={<TradesPage />} />
        <Route path="/trades/:tradeId" element={<TradesDetailPage />} />
        <Route path="/insiders" element={<InsidersPage />} />
        <Route path="/insiders/:insiderId" element={<InsiderDetailPage />} />
        <Route path="/predictions" element={<PredictionsPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
